<template>
  <!--预约记录管理-->
  <div class="appointmentLog">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="appointmentLog_header">
        <div>
          状态: &nbsp;<a-radio-group v-model="type" style="margin-right: 40px"  @change="searchOk">
          <a-radio-button value="PENDING">
            待联系
          </a-radio-button>
          <a-radio-button value="ARRENGED">
            已安排
          </a-radio-button>
          <a-radio-button value="FINISH">
            已结束
          </a-radio-button>
          <a-radio-button value="CANCEL">
            已取消
          </a-radio-button>
        </a-radio-group>
        </div>
      </div>
      <div class="appointmentLog_table">
        <a-table style="margin-bottom: 5px" :scroll="{ x: 1500 }" :columns="columns"  :data-source="InfoTable" :pagination="false" rowKey="id"
        >
          <span slot="state" slot-scope="text,item">
            {{item.status==='PENDING'?'待确认':item.status==='ARRENGED'?'已安排':item.status==='FINISH'?'已结束':item.status==='CANCEL'?'已取消':item.status}}
          </span>
          <span slot="theChannel" slot-scope="text,item">
            {{ item.channel == 'APP' ? item.channel: item.channel == 'MINA' ? '小程序' : '未知'}}
            <!-- {{item.status==='PENDING'?'待确认':item.status==='ARRENGED'?'已安排':item.status==='FINISH'?'已结束':item.status==='CANCEL'?'已取消':item.status}} -->
          </span>
          <span slot="operate" slot-scope="text,item">
            <a style="margin-right: 5px"  v-if="item.status==='PENDING'" @click="editBtn(item.id,'ARRENGED')">安排</a>
            <a style="margin-right: 5px"  v-if="item.status==='ARRENGED'" @click="editBtn(item.id,'FINISH')">结束</a>
            <a style="margin-right: 5px"  v-if="item.status==='FINISH'" @click="editBtn(item.id,'ARRENGED')">重新安排</a>
            <a v-if="item.status==='PENDING' || item.status==='FINISH' || item.status==='ARRENGED'" @click="editBtn(item.id,'CANCEL')">取消</a>
          </span>

        </a-table>

        <div style="display: flex;justify-content: flex-end;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="pageChange"/>
        </div>
      </div>
<!--      安排会议Id-->
      <a-modal v-model="meetShow" title="安排 " @ok="meetOk">
        <div style="display: flex;align-items: center">
          咨询会议：
          <a-select v-model="meetID" placeholder="请选择" style="width:80%">
            <a-select-option v-for="list in meetData" :key="list.id" :value="list.id">
              {{ list.title }}
            </a-select-option>
          </a-select>
        </div>
      </a-modal>


    </a-spin>
  </div>


</template>

<script>
import {getAppointLog, getConsultationMeet, getConsultationMeet2, putAppointLog} from "@/service/zoomMeet_api";

export default {
  name: "appointmentLog",
  data(){
    return{
      spinning: false,
      meetShow:false,
      type:'PENDING',
      indicator: <a-icon type="loading" style="font-size: 24px" spin/>,
      searchID:null,
      meetID:null,
      addShow: false,
      editShow: false,
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      InfoTable:[],
      meetData:[],
      columns: [
        {
          title: '医生姓名',
          dataIndex: 'doctorName',
        },
        {
          title: '医生医院',
          dataIndex: 'doctorHospital',
        },
        {
          title: '医生电话',
          dataIndex: 'doctorTel',
        },
        {
          title: '专家姓名',
          dataIndex: 'expertName',
        },
        {
          title: '专家医院',
          dataIndex: 'expertHospital',
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'state'},
        },
        {
          title: '渠道',
          dataIndex: 'channel',
          scopedSlots: {customRender: 'theChannel'},
        },
        {
          title: '预约时间',
          dataIndex: 'createdTime',
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {customRender: 'operate'},
        },
      ],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"预约记录管理")
  },
  mounted() {
    this.getLogData(1,this.type)
  },
  methods:{
    async getLogData(pageNum,status){
      const info =await getAppointLog(pageNum,status)
      if (info.code===0){
        this.pagination.total=info.data.count
        this.InfoTable=info.data.rows
      }
    },
    pageChange() {
      this.getLogData(this.pagination.current,this.type)
    },
    addBtn(){
      this.addShow=true
    },
    async getLogData2(pageNum,type,title){
      const info =await getConsultationMeet2(pageNum,type,title)
      if (info.code===0){
        this.meetData=info.data.rows
      }
    },
    async editBtn(id,type){
      this.editId=id
      if (type==='ARRENGED'){
        this.getLogData2(1)
        this.meetShow=true
      }else {
        const data={
          id:id,
          status:type
        }
        const edit = await putAppointLog(data)
        if (edit.code===0){
          this.$message.success('操作成功')
          this.getLogData(this.pagination.current,this.type)
        }
      }
    },
    async meetOk(){
      const data={
        id:this.editId,
        status:'ARRENGED',
        meetId:this.meetID
      }
      const edit = await putAppointLog(data)
      if (edit.code===0){
        this.$message.success('操作成功')
        this.getLogData(this.pagination.current,this.type)
        this.meetShow=false
      }
    },
    searchOk(e){
      this.type=e.target.value
      this.getLogData(1,e.target.value)
    },

  },
}
</script>

<style lang="scss" scoped>
.appointmentLog{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .appointmentLog_header{
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .appointmentLog_table{
    margin-top: 10px;
    padding: 15px;
    background: white;
  }
}
</style>
